<template>
  <div class="ibox" ref="representative">
    <div class="ibox-title">
      <h4>{{$t('eurofiscalis_company.representative.title',[$t("COUNTRIES."+countryCode)])}}</h4>
      <div class="ibox-tools">
        <a class="collapse-link">
            <i class="fa fa-chevron-up"></i>
        </a>
      </div>
    </div>
    <div v-if="representative" class="ibox-content">
      {{$t('eurofiscalis_company.representative.sipsi-note')}}
      <ul class="list-group clear-list">
        <li class="list-group-item fist-item">
            {{$t('company.info.name')}}
            <span class="float-right">{{representative.name}}</span>
        </li>
        <li class="list-group-item">
            {{$t('address.form.addressLine')}}
            <span class="float-right">{{representative.address.addressLines[0]}}</span>
        </li>
        <li class="list-group-item">
            {{$t('address.form.postalCode')}}
            <span class="float-right">{{representative.address.postalCode}}</span>
        </li>
        <li class="list-group-item">
            {{$t('address.form.city')}}
            <span class="float-right">{{representative.address.city}}</span>
        </li>
        <li class="list-group-item">
            {{$t('address.form.country')}}
            <span class="float-right">{{$t("COUNTRIES."+representative.address.countryCode.toUpperCase())}}</span>
        </li>
        <li class="list-group-item">
            {{$t('phones.form.phone')}}
            <span class="float-right">{{representative.phones[0].number}}</span>
        </li>
        <li class="list-group-item">
            {{$t('emails.form.email')}}
            <span class="float-right">{{representative.emails[0].email}}</span>
        </li>
        <li class="list-group-item" v-if="representative.siret">
            {{$t('company.identifiers.siret')}}
            <span class="float-right">{{representative.siret}}</span>
        </li>
        <li class="list-group-item" v-if="representative.siren">
            {{$t('company.identifiers.siren')}}
            <span class="float-right">{{representative.siren}}</span>
        </li>
        <li class="list-group-item" v-if="representative.vatNumber">
            {{$t('company.info.vatNumber')}}
            <span class="float-right">{{representative.vatNumber}}</span>
        </li>
        <li class="list-group-item">
            {{$t('eurofiscalis_company.representative.name')}}
            <span class="float-right">{{representative.contact.name}}</span>
        </li>
        <li class="list-group-item">
            {{$t('profile.info.dateOfBirth')}}
            <span class="float-right">{{representative.contact.dateOfBirth}}</span>
        </li>
        <li class="list-group-item">
            {{$t('profile.info.cityOfBirth')}}
            <span class="float-right">{{representative.contact.cityOfBirth}}</span>
        </li>
        <li class="list-group-item">
            {{$t('profile.info.countryOfBirth')}}
            <span class="float-right">{{representative.contact.countryOfBirth}}</span>
        </li>
        <li class="list-group-item">
            {{$t('profile.info.countryOfNationality')}}
            <span class="float-right">{{representative.contact.countryOfNationality}}</span>
        </li>
        <li class="list-group-item">
            {{$t('eurofiscalis_company.representative.personalIdentificationNumber')}}
            <span class="float-right">{{representative.personalIdentificationNumber}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';  
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import { enableIbox, disableIbox} from '../../../../theme/inspinia-jquery'; 
import * as api from '@fwk-client/utils/api';

@Component({
  components: {}
})
export default class Representative extends Vue {

  @Prop({
    type: String,
    required: true
  }) readonly countryCode!: string

  representative:any = null;

  created() {
    // We get the representative details
    
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/eurofiscalis/workers/representative/'+this.countryCode, options).then((response:any) => {
      if(response.representative) {  
        this.representative = response.representative;
      }
    });
  }

  mounted() {
    enableIbox(this.$refs.representative as HTMLElement);
  }

  beforeDestroy() {
    disableIbox(this.$refs.representative as HTMLElement);
  }

}
</script>